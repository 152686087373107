import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-8.css';

const PageEight = forwardRef((props, ref) => (
  <>
    <SEO title="Page 8" />
    <main className="page page8">
      <div className="page__image-container">
        <StaticImage
          alt="Amanda talking to Jimbugs who is standing in the dryer"
          aspectRatio={3500 / 4765}
          className="page__image page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-8.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            &ldquo;And what are fuzzer-fuzzer... whatever-they-are... and those other things?&rdquo;
            Amanda wanted to know
            {' '}
            <strong>everything!</strong>
          </p>
          <p>
            Jimbugs had an idea.
          </p>
          <p>
            &ldquo;Follow me and I’ll show you.&rdquo; He started walking to the back of the dryer.
          </p>
          <p>
            &ldquo;But I’m too big to fit in there!&rdquo;Amanda cried.
          </p>
          <p>
            &ldquo;Oh, you are really big.&rdquo; Jimbugs was disappointed.
          </p>
          <p>
            Then he remembered...
          </p>
        </div>
      </div>
      <PreviousPage to="/page-7" />
      <NextPage to="/page-9" />
    </main>
  </>
));

const PageEightContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageEight ref={textRef} />;
};

export default PageEightContainer;
